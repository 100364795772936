<template>
  <v-card>
    <v-card-title class="text-h4 grey lighten-2">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <span class="me-2"> Chat Statistics </span>
        </v-col>
      </v-row>
    </v-card-title>

    <v-row class="mb-3 my-3">
      <v-col cols="12" md="3">
        <!-- <v-card-title class="align-start">
      <span>Total Operator Perfomance</span>
      <v-spacer></v-spacer>
      <v-btn
        class="me-n3 mt-n2"
        icon
        small
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title> -->
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          bottom
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <span class="font-weight-semibold text--primary me-1">{{ $t('Date') }} :</span>
          <span>{{ dateRangeText }}</span> -->
            <v-text-field
              label="Chat Statistic"
              :prepend-icon="icons.mdiCalendarMonth"
              :value="dateRangeText"
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
            <!-- <v-btn
            icon
            small
            v-on="on"
          >
            <v-icon>
              {{ icons.mdiCalendarMonth }}
            </v-icon>
          </v-btn> -->
          </template>
          <v-date-picker v-model="dates" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="#ffeeee" @click="menu = false">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn text color="success" @click="getOpsStatistic(dates)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" lg="3" md="3" sm="3" xs="3">
        <div class="d-flex">
          <span class="mt-5 me-3">Chat</span> <v-switch v-model="uniqData"></v-switch>
          <span class="mt-5 ms-3">Contact</span>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-3 mb-3">
      <v-col cols="12">
        <v-card-title class="align-start text-h6 font-weight-semibold">
          <span>New {{ uniqData ? 'Contact' : 'Chat' }}</span>
        </v-card-title>
        <vue-apex-charts
          v-if="!uniqData"
          type="area"
          height="300"
          :options="chartCreatedAt"
          :series="messageStatisticNew"
        ></vue-apex-charts>
        <vue-apex-charts
          v-if="uniqData"
          type="area"
          height="300"
          :options="chartCreatedAt"
          :series="messageStatisticUniq"
        ></vue-apex-charts>
      </v-col>
    </v-row>
    <v-row class="mt-3 mb-3">
      <v-col cols="12">
        <v-card-title class="align-start text-h6 font-weight-semibold">
          <span>Incoming {{ uniqData ? 'Contact' : 'Chat' }}</span>
        </v-card-title>
        <vue-apex-charts
          v-if="!uniqData"
          type="area"
          height="300"
          :options="chartCreatedAt"
          :series="messageStatisticIncoming"
        ></vue-apex-charts>
        <vue-apex-charts
          v-if="uniqData"
          type="area"
          height="300"
          :options="chartCreatedAt"
          :series="messageStatisticIncomingUniq"
        ></vue-apex-charts>
      </v-col>
    </v-row>
    <v-row class="mt-3 mb-3">
      <v-col cols="12">
        <v-card-title class="align-start text-h6 font-weight-semibold">
          <span>Outgoing {{ uniqData ? 'Contact' : 'Chat' }}</span>
        </v-card-title>
        <vue-apex-charts
          v-if="!uniqData"
          type="area"
          height="300"
          :options="chartCreatedAt"
          :series="messageStatisticOutcoming"
        ></vue-apex-charts>
        <vue-apex-charts
          v-if="uniqData"
          type="area"
          height="300"
          :options="chartCreatedAt"
          :series="messageStatisticOutcomingUniq"
        ></vue-apex-charts>
      </v-col>
    </v-row>
    <!-- <v-row class="mt-3 mb-3">
      <v-col
      sm= '1'
      md= '1'
      lg= '1'
      cols='12'
      ></v-col>
       <v-col
      sm= '9'
      md= '9'
      lg= '9'
      cols='12'
      >
        <v-card>
    <v-card-title class="align-start">
      <span>Total Message {{dates}}</span>
      <v-spacer></v-spacer>
      <v-btn
        class="me-n3 mt-n2"
        icon
        small
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>
      <v-card-text>
      <v-list class="pt-0">

        <v-list-item
          v-for="(data) in totalDataOperator"
          :key="data.email"
          class="d-flex align-center px-0 mt-3"
        >

          <div class="d-flex align-center flex-grow-1 flex-wrap text-no-wrap">
            <div class="me-2">
              <v-list-item-title class="text-sm font-weight-semibold">
              {{data.email}}
              </v-list-item-title>

              <div class="d-flex align-center">
                <span>Total Reply : {{data.total_reply}}</span>
              </div>
            </div>

            <v-spacer></v-spacer>

            <v-chip
              class="font-weight-semibold"
            >
            AVG Reply : {{data.avg_reply_time}}
            </v-chip>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
    </v-card>
      </v-col>
       <v-col
      sm= '1'
      md= '1'
      lg= '1'
      cols='12'
      ></v-col>
    </v-row> -->
  </v-card>
</template>

<script>
/* eslint-disable */
// import PouchDB from 'pouchdb-browser'
import {
  mdiCalendarMonth,
  mdiChartBar,
  mdiCloseCircle,
  mdiDeleteOutline,
  mdiExport,
  mdiEyeOutline,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlusCircle,
} from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'
import pouchDbMixin from '../../../mixins/pouchDbMixin'
import { TimeFormater } from './../../../helpers'
// PouchDB.adapter('worker', require('worker-pouch'))
export default {
  name: 'OperatorList',
  components: {
    VueApexCharts,
  },
  mixins: [pouchDbMixin],
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiPencilOutline,
        mdiCalendarMonth,
      },
    }
  },
  data: () => ({
    //chart
    uniqData: false,
    messageStatistic: [],
    messageStatisticNew: [],
    messageStatisticUniq: [],
    messageStatisticIncoming: [],
    messageStatisticOutcoming: [],
    messageStatisticIncomingUniq: [],
    messageStatisticOutcomingUniq: [],
    operatorStatistic: [],
    time: [
      '00:00',
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21.00',
      '22.00',
      '23:00',
    ],
    totalDataOperator: [],
    incomingMessage: 0,
    outgoingMessage: 0,
    totalReplyDay: 0,
    dataStatisticDay: [],
    dataListChannel: [],
    dataListUser: [],
    listUserData: [],
    chartCreatedAt: {},
    userDataCreated: [],
    menu: false,
    rangeDates: [],
    dates: [
      new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substring(0, 10),
      new Date().toISOString().substring(0, 10),
    ],

    // DIALOG Error Message
    dialogErrorMessage: false,
    errorMessage: '',

    // Confirm delete User
    confirmDeleteMessage: false,
    deleteUserdata: {},

    //loading
    loading: false,

    // SETTING
    columnsSetting: null,
    settingFilterSetting: null,
    tableColumns: [],
    itemsFilter: [],
    // DIALOG Add Operator
    dialogOperator: false,
    model: {
      operatorEmail: '',
    },
    alertText: '',

    dialogAlert: false,
    // Dialog Add Channel
    dialogSelectChannel: false,
    dataSubId: [],
    dataMenu: [],
    currentItem: {
      role: '',
      auto_assign: false,
      view_assign_only: false,
      limited_channel: false,
      enabled_channel: [],
      limited_menu: false,
      enabled_menu: [],
      listInstances: [],
    },
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    // listInstances() {
    //   const inst = this.$store.getters['auth/getListInstance']
    //   // //console.log(inst, `INI INSTANCE`)
    //   if (inst) {
    //     return inst.value
    //   } else {
    //     return []
    //   }
    // },
    listWorkspaces() {
      return this.$store.getters['auth/getListWorkspaces']
    },
    headers() {
      return [
        { text: 'Email', value: 'email', sortable: true },
        { text: 'Average Reply Time', value: 'avg_reply_time', sortable: true, align: 'center' },
        { text: 'Total Replied', value: 'total_reply', sortable: true, align: 'center' },
      ]
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    dataListUsers() {
      return this.$store.getters['auth/getListUsers']
    },
    listMenu() {
      return this.user.package === 'basic'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Broadcasts']
        : this.user.package === 'pro'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts']
        : this.user.package === 'business'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users']
        : ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users', 'Business+']
    },
    viewAssign() {
      return this.currentItem.view_assign_only
    },
    limitedChannel() {
      return this.currentItem.limited_channel
    },
    limitedMenu() {
      return this.currentItem.limited_menu
    },
  },
  async mounted() {
    this.loading = true
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.listInstances = instances?.value || []
    await this.$store
      .dispatch('auth/fetchListUser', {
        sub_id: this.user.sub_id,
      })
      .then(data => {
        this.loading = false
      })

    await this.$store.getters['auth/getListUsers']
    this.dataListUsers.forEach(el => {
      this.listUserData.push(el.email)
    })
    this.listInstances.forEach(el => {
      this.dataListChannel.push(`${el._id + '-' + el.phone_number}`)
    })
    // //console.log(this.dataListChannel, 'aaaaaaaaaaa')
    await this.getOpsStatistic(this.dates)

    ////console.log(this.listUserData, ' == data list user')
    // await this.getStatisticChart(this.dates)
    // await this.$store
    //   .dispatch('auth/fetchListUser', {
    //     sub_id: this.user.sub_id,
    //   })
    //   .then(data => {
    // this.loading = false
    //   })

    // await this.$store.getters['auth/getListUsers']
    // //console.log(this.dataListUsers, ' == data list user')
  },
  watch: {
    viewAssign() {
      if (this.currentItem.view_assign_only) {
        this.currentItem.limited_channel = false
        this.currentItem.limited_menu = false
        this.dataMenu = []
        this.dataSubId = []
      }
    },
    limitedChannel() {
      if (!this.currentItem.limited_channel) {
        this.dataSubId = []
      }
    },
    limitedMenu() {
      if (!this.currentItem.limited_menu) {
        this.dataMenu = []
      } else {
        if (this.currentItem.enabled_menu.length === 0) {
          this.dataMenu =
            this.user.package === 'basic'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Broadcasts']
              : this.user.package === 'pro'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts']
              : this.user.package === 'business'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users']
              : ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users', 'Business+']
        }
      }
    },
  },
  methods: {
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val

      formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

      formatted = formatted.split('"').join('""')

      /**
       * Excel accepts \n and \r in strings, but some other CSV parsers do not
       * Uncomment the next two lines to escape new lines
       */
      // .split('\n').join('\\n')
      // .split('\r').join('\\r')

      return `"${formatted}"`
    },
    exportTableList() {
      // naive encoding to csv format
      const content = [this.headers.map(col => this.wrapCsvValue(col.text))]
        .concat(
          this.dataStatisticDay.map(row =>
            this.headers
              .map(col => {
                if (col.field === 'channel') {
                  return row.channel
                }
                if (col.field === 'statistic') {
                  return `"sent: ${row.statistic.sent === undefined ? '0' : row.statistic.sent} received: ${
                    row.statistic.received === undefined ? '0' : row.statistic.received
                  } read: ${row.statistic.read === undefined ? '0' : row.statistic.read} replied: ${
                    row.statistic.replied === undefined ? '0' : row.statistic.replied
                  }"`
                }

                return this.wrapCsvValue(row[col.value], undefined)
              })
              .join(','),
          ),
        )
        .join('\r\n')

      const anchor = document.createElement('a')
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`
      anchor.target = '_blank'
      anchor.download = `${new Date()}-${'User Statistic'}.csv`
      anchor.click()
    },
    async getOpsStatistic(date) {
      this.$refs.menu.save(date)
      if (date && date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          this.rangeDates = this.getDateRange(new Date(date[1]), new Date(date[0]))
        } else {
          this.rangeDates = this.getDateRange(new Date(date[0]), new Date(date[1]))
        }
      } else {
        const startDate = `${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getDate()}`
        const endDate = `${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getDate()}`

        this.rangeDates = this.getDateRange(new Date(startDate), new Date(endDate))
      }
      const chart = {
        chart: {
          stacked: true,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        colors: ['#008FFB', '#00E396', '#CED4DC', '#ff4c51', '#ffb400', '#1F979E'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },

        xaxis: {
          categories: [],
        },
      }
      const range = JSON.parse(JSON.stringify(this.rangeDates))
      this.chartCreatedAt = chart
      this.chartCreatedAt.xaxis.categories = range
      const statMessage = await this.$store.dispatch('rooms/statisticMessage', {
        subId: this.user.sub_id,
        dateRange: this.rangeDates,
        listChannel: this.dataListChannel,
      })
      // //console.log(statMessage)
      if (statMessage.data.status) {
        let newMsg = []
        let newMsgUniq = []
        let incomingMsg = []
        let outcomingMsg = []
        let incomingMsgUniq = []
        let outcomingMsgUniq = []
        statMessage.data.allStatisticChatIncoming.forEach(el => {
          let name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          let objincoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }
          incomingMsg.push(objincoming)
        })
        statMessage.data.allStatisticChatOutcoming.forEach(el => {
          let name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          let objoutcoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }
          // //console.log(objoutcoming)
          outcomingMsg.push(objoutcoming)
        })
        statMessage.data.allStatisticNewChat.forEach(el => {
          let name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          let objincoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }
          newMsg.push(objincoming)
        })

        //! uniq
        statMessage.data.allStatisticChatIncomingUniq.forEach(el => {
          let name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          let objincoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }
          incomingMsgUniq.push(objincoming)
        })
        statMessage.data.allStatisticChatOutcomingUniq.forEach(el => {
          let name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          let objoutcoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }
          // //console.log(objoutcoming)
          outcomingMsgUniq.push(objoutcoming)
        })
        statMessage.data.allStatisticNewChatUniq.forEach(el => {
          let name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          let objincoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }
          newMsgUniq.push(objincoming)
        })

        this.messageStatisticIncomingUniq = JSON.parse(JSON.stringify(incomingMsgUniq))

        this.messageStatisticOutcomingUniq = JSON.parse(JSON.stringify(outcomingMsgUniq))

        this.messageStatisticUniq = JSON.parse(JSON.stringify(newMsgUniq))

        this.messageStatisticIncoming = JSON.parse(JSON.stringify(incomingMsg))

        this.messageStatisticOutcoming = JSON.parse(JSON.stringify(outcomingMsg))

        this.messageStatisticNew = JSON.parse(JSON.stringify(newMsg))
      }

      this.menu = false
    },
    async getStatisticChart(date) {
      if (date && date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          this.rangeDates = this.getDateRange(new Date(date[1]), new Date(date[0]))
        } else {
          this.rangeDates = this.getDateRange(new Date(date[0]), new Date(date[1]))
        }
      } else {
        const startDate = `${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getDate()}`
        const endDate = `${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getDate()}`

        this.rangeDates = this.getDateRange(new Date(startDate), new Date(endDate))
      }
      const statOps = await this.$store.dispatch('rooms/statisticOperator', {
        subId: this.user.sub_id,
        dateRange: this.rangeDates,
      })
      const chart = {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },

        colors: [
          // $themeColors.warning,
          // $themeColors.error,
          // $themeColors.secondary,
          // $themeColors.info,
          // $themeColors.success,

          '#ab7efd',
          '#F0D9FF',
          '#e0cffe',
          '#BFA2DB',
          '#b992fe',
          '#b391ec',
          '#b9a3dc',
          '#bdb5ca',
          '#bec6b9',
          '#8c62dd',
          '#966be8',
          '#a175f2',
          '#b588ff',
          '#c091ff',
          '#ca9bff',
          '#b089f3',
          '#b594e8',
          '#b89fde',
          '#bba9d4',
          '#bdb4c9',
          '#bebebe',
          '#bb93fe',
          '#caa8ff',
          '#d9beff',
          '#e6d3ff',
          '#f3e9ff',
          '#ffffff',
          '#8d68ce',
          '#6f53a1',
          '#533f76',
          '#392c4e',
        ],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        fill: {
          opacity: 1,
          type: ['solid', 'gradient'],
        },
        xaxis: {
          categories: [],
        },
      }
      // this.userDataCreated = JSON.parse(JSON.stringify(statOps.data.data ? statOps.data.data : []))
      const range = JSON.parse(JSON.stringify(this.rangeDates))
      this.chartCreatedAt = chart

      this.chartCreatedAt.xaxis.categories = range
      this.menu = false

      // //console.log(statOps, 'aaaaaaaaa')
    },
    getDateRange(startDate, endDate) {
      const dates = []
      let currentDate = startDate
      const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)

        return date
      }
      while (currentDate <= endDate) {
        dates.push(new Date(currentDate).toISOString().substring(0, 10))
        currentDate = addDays.call(currentDate, 1)
      }

      // //console.log(dates, 'oooooooooooo')
      return dates
    },
    async closeDialog() {
      this.dialogSelectChannel = false
      this.loading = true
      await this.$store
        .dispatch('auth/fetchListUser', {
          sub_id: this.user.sub_id,
        })
        .then(data => {
          this.loading = false
        })
    },
    async saveChannel() {
      this.dialogSelectChannel = false
      if (this.currentItem.view_assign_only) {
        this.currentItem.limited_channel = false
        this.currentItem.limited_menu = false
      }
      this.currentItem.enabled_channel = []
      if (this.currentItem.limited_channel) {
        this.dataSubId.forEach((data, index) => {
          if (data) {
            this.currentItem.enabled_channel.push(this.listInstances[index]._id)
          }
        })
      }
      this.currentItem.enabled_menu = []
      if (this.currentItem.limited_menu) {
        this.dataMenu.forEach((data, index) => {
          if (data) {
            this.currentItem.enabled_menu.push(this.listMenu[index])
          }
        })
      }
      var res = await this.$store.dispatch('auth/editUserSubscription', {
        id: this.currentItem.id,
        auto_assign: this.currentItem.auto_assign,
        view_assign_only: this.currentItem.view_assign_only,
        limited_channel: this.currentItem.limited_channel,
        enabled_channel: this.currentItem.enabled_channel,
        limited_menu: this.currentItem.limited_menu,
        enabled_menu: this.currentItem.enabled_menu,
      })
      if (!res.status) {
        this.errorMessage = res.errorMessage
        this.dialogErrorMessage = true
      }
      await this.$store.dispatch('auth/fetchListUser', {
        sub_id: this.user.sub_id,
      })
      await this.$store.dispatch('auth/getChannelAccess', {
        sub_id: this.user.sub_id,
        email: this.user.email,
        token: this.user.token,
      })
    },
    getChannelsName(id) {
      const obj = this.listInstances.find(x => x._id === id)
      if (obj) {
        return (
          (obj.label.startsWith('-') || obj.label.includes('Default') || obj.label.includes('62')
            ? obj.label_server
            : obj.label) +
          ' (' +
          obj.phone_number +
          ')'
        )
      }
      return ''
    },
    async addSelectedChannel(item) {
      this.dialogSelectChannel = true
      this.currentItem = {
        ...item,
      }
      this.listInstances.forEach((instance, index) => {
        this.dataSubId[index] = item.enabled_channel && item.enabled_channel.findIndex(x => x === instance._id) > -1
      })
      this.listMenu.forEach((menu, index) => {
        this.dataMenu[index] = item.enabled_menu && item.enabled_menu.findIndex(x => x === menu) > -1
      })
    },
    async addOperator() {
      let limitOps = await this.$store.dispatch('subscriptions/getSubscriptionInfo', this.user)
      // //console.log(limitOps,"aaaaaaaaaaaaaaaaaa")
      if (this.dataListUsers.length < limitOps.data.data.dataValues.max_operator) {
        this.dialogOperator = true
      } else {
        this.showAlert(this.$t('warningmaxoperator'))
      }
    },
    showAlert(warningText) {
      this.alertText = warningText
      this.dialogAlert = true
    },
    msToTime(duration) {
      return TimeFormater.msToTime(duration)
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    async saveUser() {
      if (this.validateEmail(this.model.operatorEmail)) {
        const currentWorkspace = this.listWorkspaces.find(
          workspace => workspace.role === 'Admin' && workspace.sub_id === this.user.sub_id,
        )
        //console.log(currentWorkspace, this.listWorkspaces, `INI DIA`)
        var res = await this.$store.dispatch('auth/addUser', {
          email: this.model.operatorEmail,
          sub_id: this.user.sub_id,
          role: 'Operator',
          auto_assign: false,
          view_assign_only: false,
          limited_channel: false,
          enabled_channel: [],
          workspace_name: currentWorkspace.workspace_name,
        })
        if (res.status) {
          await this.$store.dispatch('auth/fetchListUser', {
            sub_id: this.user.sub_id,
          })
        } else {
          this.errorMessage = res.errorMessage
          this.dialogErrorMessage = true
        }
        this.model.operatorEmail = ''
        this.dialogOperator = false
      } else {
        this.errorMessage = 'Please Input in email Format'
        this.model.operatorEmail = ''
        this.dialogErrorMessage = true
        this.dialogOperator = false
      }
    },
    async deleteUser(data) {
      this.loading = true
      var res = await this.$store.dispatch('auth/deleteUser', {
        email: data.email,
        sub_id: data.sub_id,
      })
      if (res.status) {
        await this.$store.dispatch('auth/fetchListUser', {
          sub_id: this.user.sub_id,
        })
      } else {
        this.errorMessage = res.errorMessage
        this.dialogErrorMessage = true
      }
      this.loading = false
      this.deleteUserdata = {}
      this.confirmDeleteMessage = false
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.table-broadcast ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
.chip-margin-left {
  margin-left: 1px;
}
.search-in-detail-status {
  margin-right: 5px;
  margin-bottom: 5px;
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>
