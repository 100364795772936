<template>
  <div>
      <operator-history v-if="historyPage" />
      <operator-list v-if="listPage" />
      <operator-statistic v-if="statisticPage" />
  </div>
</template>

<script>
import { OperatorHistory, OperatorStatistic, OperatorList, } from './page'

export default {
  name: 'operator',
  props: ['historyPage', 'listPage', 'statisticPage'],
  components: {
    OperatorList,
    OperatorHistory,
    OperatorStatistic,
  },
}
</script>

<style>
</style>
