<template>
  <v-card class="example">
    <v-card-text>
      <v-row class="justify-space-between">
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                :label="$t('broadcasts.date')"
                :prepend-icon="icons.mdiCalendarClock"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="#ffeeee"
                @click="menu = false"
              >
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="updateTableHistory"
              > OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-row class="">
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                block
                color="primary"
              >
                <v-icon
                  dark
                  left
                >
                  {{ icons.mdiPlusCircle }}
                </v-icon>
                <vue-excel-xlsx
                  :data="broadcastListAll"
                  :columns="historyListTable"
                  :file-name="'HistoryTable'"
                  :file-type="'xlsx'"
                  :sheet-name="'HistoryTable'"
                  class="primary-text"
                >
                  {{ $t('exportToCsv') }}
                </vue-excel-xlsx>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      item-key="table_id"
      :headers="headers"
      :items="historyListTableFilter"
      :items-per-page="10"
      sort-desc
      :loading="loading"
      multi-sort
      sort-by="timestamp"
      class="text-no-wrap text-center"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel'),
      }"
    >
      <template #[`item.rating`]="{ item }">
        <v-rating
          v-model="item.rating"
          background-color="warning"
          color="warning"
          @input="setRatingHistoryOperatorFirestore(item)"
        ></v-rating>
      </template>
      <template #[`item.chat`]="{ item }">
        <v-icon @click="openShow5History(item)">
          {{ icons.mdiEyeOutline }}
        </v-icon>
      </template>
      <template #[`item.reply_time`]="{ item }">
        {{ msToTime(item.reply_time) }}
      </template>
      <template #[`item.duration`]="{ item }">
        {{ msToTime(item.duration) }}
      </template>
      <template #[`item.timestamp`]="{ item }">
        {{ dateFormater(new Date(item.timestamp * 1000)) }}
      </template>
      <!-- <template #[`item.attachments`]="{ item }">
        <v-icon @click="openShowAttachment(item.attachments)">
          {{ icons.mdiEyeOutline }}
        </v-icon>
      </template> -->
    </v-data-table>
    <!-- dialog Chat history -->
    <v-dialog
      v-model="show5History"
      width="700px"
    >
      <v-card>
        <v-card-text>
          <v-row class="justify-space-between">
            <v-col
              cols="12"
              md="6"
            >
              <v-card-title>
                {{ $t('history.headerMarkupTable') }}
              </v-card-title>
            </v-col>
            <!-- <v-col
              cols="12"
              md="6"
              class="text-end"
            >
              <v-btn
                icon
                class="block-condition"
                @click="closeShow5History"
              >
                <v-icon dark>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-text>
          <div v-if="latestMessage.length !== 0">
            <!-- data chat history -->
            <chat-window
              height="300px"
              :current-user-id="currentUserId"
              :rooms="rooms"
              :rooms-origin="rooms"
              :messages="messages"
              :rooms-loaded="true"
              :messages-loaded="true"
              :single-room="true"
              :loading-rooms="false"
              :show-add-room="false"
              :show-new-messages-divider="false"
              :show-reaction-emojis="false"
              :show-footer="false"
              :show-search="false"
              :menu-actions="menuActions"
              :message-actions="messageActions"
              :hide-messages-started="true"
              :replies-history="true"
              @fetch-messages="fetchMessagesHistory"
              @open-file="openFile"
            />
            <!-- <div v-for="item in latestMessage" :key="item.keyId" :sort-desc="[false]">
              <div class="chat">
                <div class="chat-box">
                  <div v-if="item.data.key.fromMe === true" class="chat-r">
                    <div class="sp"></div>
                    <div class="mess mess-r">
                      <p>{{ item.caption }}</p>
                      <div class="check">
                        <span>{{ $moment(item.data.messageTimestamp * 1000).format('YYYY MMM DD') }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.data.key.fromMe === false" class="chat-l">
                    <div class="mess">
                      <p>{{ item.caption }}</p>
                      <div class="check">
                        <span>{{ $moment(item.data.messageTimestamp * 1000).format('YYYY MMM DD') }}</span>
                      </div>
                    </div>
                    <div class="sp"></div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <!-- if no data chat history -->
          <v-card-title
            v-if="latestMessage.length === 0"
            class="justify-center"
          >
            <h6 class="text-center">
              {{ $t('history.chatHistoryData') }}
            </h6>
          </v-card-title>
          <!-- <pre>{{ latestMessage }}</pre> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class=""
            @click="closeShow5History"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable */
import { CsvWrapper, TimeFormater } from '../../../helpers'
import pouchDbMixin from '../../../mixins/pouchDbMixin'
import inboxMixin from '../../../mixins/inboxMixin'
import utilsMixin from '../../../mixins/utilsMixin'
import ChatWindow from '@/plugins/vue-advanced-chat/lib/ChatWindow'
import '@/plugins/vue-advanced-chat/styles/vue-advanced-chat.css'
import {
  mdiCloseCircle,
  mdiEyeOutline,
  mdiChartBar,
  mdiExport,
  mdiPlusCircle,
  mdiMagnify,
  mdiDeleteOutline,
  mdiClose,
  mdiCalendarClock,
} from '@mdi/js'

export default {
  name: 'OperatorList',
  mixins: [pouchDbMixin, inboxMixin, utilsMixin],
  setup() {
    return {
      icons: {
        mdiClose,
        mdiMagnify,
        mdiDeleteOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiCalendarClock,
      },
    }
  },

  components: {
    ChatWindow,
  },

  data() {
    return {
      // advanced chat
      currentUserId: '',
      rooms: [],
      messages: [],
      menuActions: [],
      messageActions: [],
      // data
      date: [
        new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substring(0, 10),
        new Date(new Date().setDate(new Date().getDate())).toISOString().substring(0, 10),
      ],
      menu: false,
      loading: false,
      arr: [],
      show5History: false,
      latestMessage: [],
      attachmentData: '',
      historyListTable: [],
      columnsSetting: null,
      settingFilterSetting: null,
      tableColumns: [],
      itemsFilter: [],
      search: '',
      columnsHistoryAll: [
        {
          label: 'Assign To',
          field: 'email',
        },
        {
          label: 'Timestamp',
          field: 'timestamp',
        },
        {
          label: 'To',
          field: 'to',
        },
        {
          label: 'Reply Time',
          field: 'reply_time',
        },
        {
          label: 'Chat',
          field: 'chat',
        },
        {
          label: 'Room',
          field: 'room',
        },
      ],
      watcherHistory: null,
      watcherSetting: null,
      contactListTable: [],
    }
  },
  watch: {
    show5History(varnew) {
      if (varnew == false) {
        this.closeShow5History()
      }
    },
  },
  computed: {
    historyListTableFilter() {
      const result = this.historyListTable.filter(x => {
        let status = false
        if (this.search) {
          if (!status && x.contact && x.contact.toLowerCase().includes(this.search.toLowerCase())) {
            status = true
          }
          if (!status && x.email && x.email.toLowerCase().includes(this.search.toLowerCase())) {
            status = true
          }
          if (!status && x.channel && x.channel.toLowerCase().includes(this.search.toLowerCase())) {
            status = true
          }
          if (
            !status &&
            x.reply_time &&
            this.msToTime(x.reply_time).toLowerCase().includes(this.search.toLowerCase())
          ) {
            status = true
          }
          if (!status && x.duration && this.msToTime(x.duration).toLowerCase().includes(this.search.toLowerCase())) {
            status = true
          }
          if (
            !status &&
            x.timestamp &&
            this.dateFormater(new Date(x.timestamp * 1000))
              .toLowerCase()
              .includes(this.search.toLowerCase())
          ) {
            status = true
          }

          return status
        }
        return true
      })

      return result
    },
    headers() {
      return [
        { text: this.$t('history.assign'), value: 'email', sortable: true },
        {
          text: 'Timestamp',
          value: 'timestamp',
          sortable: true,
        },
        { text: this.$t('history.contact'), value: 'contact', sortable: true },
        { text: this.$t('history.replyTime'), value: 'reply_time', sortable: true, align: 'center' },
        { text: this.$t('history.channel'), value: 'channel', sortable: true, align: 'center' },
        { text: this.$t('history.duration'), value: 'duration', sortable: true, align: 'center' },
        { text: this.$t('chat'), value: 'chat', sortable: true },
        // { text: this.$t('history.rating'), value: 'rating', sortable: true },
      ]
    },

    dateRangeText() {
      return this.date.join(' ~ ')
    },
  },
  async mounted() {
    this.loading = true
    const itemRoom = await this.loadContacts(true)
    this.contactListTable = itemRoom
    await this.connectHistory()
    await this.getHistory()
    this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_HISTORY, async payload => {
      await this.getHistory()

      if (this.$refs.menu) {
        this.$refs.menu.save(this.date)
      }
    })
  },
  async beforeDestroy() {
    if (this.watcherHistory !== null) this.watcherHistory.cancel()
    if (this.watcherSetting !== null) this.watcherSetting.cancel()
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_HISTORY)
  },
  methods: {
    openFile({ file }) {
      if (file.action == 'preview') {
        window.open(file.file.url, '_blank')
      } else {
        this.actionFile(file.file)
      }
    },
    async openShow5History(data) {
      const rooms = []

      if (data.room) {
        this.currentUserId = data.currentUserId

        const response = await this.$store.dispatch('inboxNavbar/getUsers', {
          subId: this.user.sub_id,
          roomId: data.room.roomId,
        })

        if (response.status) {
          data.room.users = response.data ? response.data : []

          // format phone number
          data.room.users.forEach(user => {
            if (user._id == user.username) {
              user.username = this.getContactsName(user._id)

              if (
                /\d{10,11}/.test(user.username) ||
                /\d{12,13}/.test(user.username) ||
                /\d{14,15}/.test(user.username)
              ) {
                user.username = this.formatPhoneNumber(user._id, false)
              }
            }
          })
        }

        rooms.push(data.room)
      } else {
        rooms.push({
          roomId: 1,
          roomName: 'Chats',
          avatar: '',
          unreadCount: 0,
          index: 1,
          lastMessage: {
            content: '',
            senderId: 1,
            username: 'Default',
            timestamp: '00:00',
            saved: false,
            distributed: false,
            seen: false,
            new: false,
          },
          users: [],
          typingUsers: [],
        })
      }

      this.rooms = [...rooms]

      const arr = []

      if (data.latest_message.length === 0) {
        this.latestMessage = arr
      } else {
        for (let i = 0; i <= data.latest_message.length - 1; i++) {
          arr.push(data.latest_message[i])
        }

        this.latestMessage = [...arr]
      }

      //console.log(data, 'history')
      this.show5History = true
    },
    fetchMessagesHistory({ room, options }) {
      setTimeout(() => {
        this.messages = this.latestMessage
        this.messagesLoaded = true
      })
    },
    attachmentDataConvert(data) {
      if (data === '') {
        this.attachmentData = ''
      } else {
        this.attachmentData = data
      }
    },
    exportTable() {
      const content = [this.columnsHistoryAll.map(col => CsvWrapper(col.label))]
        .concat(
          this.historyListTable.map(row =>
            this.columnsHistoryAll
              .map(col => {
                if (col.field === 'reply_time') {
                  if (row[col.field]) {
                    return row.reply_time ? this.msToTime(row.reply_time) : ''
                  }

                  return ''
                } else if (col.field === 'timestamp') {
                  return TimeFormater.dateFormater(new Date(row.timestamp * 1000))
                } else {
                  return CsvWrapper(
                    typeof col.field === 'function' ? col.field(row) : row[col.field === void 0 ? col.name : col.field],
                    col.format,
                  )
                }
              })
              .join(','),
          ),
        )
        .join('\r\n')

      const anchor = document.createElement('a')
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`
      anchor.target = '_blank'
      anchor.download = 'Replies History.csv'
      anchor.click()
    },
    dateFormater(dt) {
      return TimeFormater.dateFormater(dt)
    },
    msToTime(du) {
      const date = new Date(du * 1000)
      let hh = date.getUTCHours()
      let mm = date.getUTCMinutes()
      let ss = date.getSeconds()

      // If you were building a timestamp instead of a duration, you would uncomment the following line to get 12-hour (not 24) time
      // if (hh > 12) {hh = hh % 12;}
      // These lines ensure you have two-digits
      if (hh < 10) {
        hh = `0${hh}`
      }
      if (mm < 10) {
        mm = `0${mm}`
      }
      if (ss < 10) {
        ss = `0${ss}`
      }

      // This formats your string to HH:MM:SS
      const t = `${hh}:${mm}:${ss}`

      return t
    },
    closeShow5History() {
      this.show5History = false
      this.latestMessage = []
    },
    async getHistory(start = null, end = null) {
      this.loading = true
      this.historyListTable = await this.loadHistoryOperatorCouch(start, end)
      this.loading = false
    },
    async updateTableHistory() {
      this.$refs.menu.save(this.date)

      //console.log('updateTableHistory,', this.date)
      await this.getHistory(new Date(this.date[0]), new Date(this.date[1]))
    },
    getContactsName(phoneNumber) {
      const foundContact = this.contactListTable.find(x => x.phone_number === phoneNumber)

      if (foundContact) {
        return foundContact.name
      }

      return phoneNumber
    },
  },
}
</script>

<style lang="scss" scoped>
.table-broadcast ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
.chip-margin-left {
  margin-left: 1px;
}
.search-in-detail-status {
  margin-right: 5px;
  margin-bottom: 5px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
.card-note {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #adadad;
}
.chat {
  display: flex;
  flex-direction: column;

  background: #f1f0e8;
}
.chat-header {
  display: flex;
  cursor: pointer;
}
.profile {
  width: 100%;
  background: #036055;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0px 10px;
  position: relative;
}
.profile .pp {
  width: 50px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 32px;
}
.profile .arrow {
  display: inline-block;
  width: 30px;
  position: absolute;
  top: 19px;
  cursor: pointer;
}
.profile h2 {
  display: inline-block;
  line-height: 60px;
  vertical-align: bottom;
  color: #fff;
  font-size: 20px;
}
.profile span {
  color: #ccc;
  position: absolute;
  top: 42px;
  left: 100px;
  font-size: 14px;
}
.right .icon {
  display: inline-block;
  width: 25px;
  margin-left: 10px;
}
.profile .left {
  flex: 1;
}

.chat-box {
  background-attachment: fixed;
  padding-left: 20px;
  overflow-y: scroll;
  flex: 1;
}
/* .chat-box .img_chat {
  width: 280px;
} */

.chat-r {
  display: flex;
  color: black;
}
.chat-r .sp {
  flex: 1;
}

.chat-l {
  display: flex;
  color: black;
}
.chat-l .sp {
  flex: 1;
}

.chat-box .mess {
  max-width: 300px;
  background: #f7fcf6;
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0px;
  cursor: pointer;
}
.chat-box .mess p {
  word-break: break-all;
  font-size: 18px;
}
.chat-box .mess-r {
  background: #e2ffc7;
}
.chat-box .emoji {
  width: 20px;
}
.chat-box .check {
  float: right;
}
.chat-box .check img {
  width: 20px;
}
.chat-box .check span {
  color: #888;
  font-size: 12px;
  font-weight: 700px;
}

*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}
*::-webkit-scrollbar-thumb {
  background: #aaa;
}
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  position: relative;
  cursor: pointer;
}
.chat-footer textarea {
  display: block;
  flex: 1;
  width: 100%;
  height: 50px;
  border-radius: 60px;
  margin: 5px;
  padding: 10px;
  outline: none;
  font-size: 19px;
  padding-left: 40px;
  padding-right: 90px;
  border: 2px solid #ccc;
  color: #555;
  resize: none;
}
.chat-footer .mic {
  display: block;
  width: 55px;
  height: 55px;
  margin-right: 20px;
}

.chat-footer .emo {
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 10px;
  top: 12px;
}
.chat-footer .icons {
  position: absolute;
  right: 100px;
  top: 10px;
}
.chat-footer .icons img {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-left: 5px;
}
@media (max-width: 968px) {
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>
