<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3">
          <v-btn block color="primary" @click="addOperator">
            <v-icon dark left>
              {{ icons.mdiPlusCircle }}
            </v-icon>
            {{ $t('add') }} User
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      item-key="email"
      :items="dataListUsers"
      :items-per-page="10"
      :sort-desc="false"
      :loading="loading"
      multi-sort
      must-sort
      class="text-no-wrap text-center"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel'),
      }"
    >
      <template #[`item.avg_reply`]="{ item }">
        {{ item.avg_reply !== null ? msToTime(item.avg_reply) : '00:00:00.0' }}
      </template>
      <template #[`item.status`]="{ item }">
        <v-switch v-model="item.isActive" @change="e => changeStatus(e, item.id)"></v-switch>
      </template>
      <template #[`item.role`]="{ item }">
        {{ item.expand.crmRole.role }}
      </template>
      <template #[`item.assign_chat`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="contact-tooltip">{{ item['assign_chat_total'] }} Contact</span>
          </template>
          <div v-for="phone in item['assign_chat']">
            <span>{{ phone }}</span>
            <v-spacer></v-spacer>
          </div>
        </v-tooltip>
      </template>
      <!-- <template #[`item.auto_assign`]="{ item }">
        <v-checkbox v-model="item.auto_assign" class="align-center justify-center" @click="addSelectedChannel(item)">
        </v-checkbox>
      </template>
      <template #[`item.view_assign_only`]="{ item }">
        <v-checkbox
          v-model="item.view_assign_only"
          class="align-center justify-center"
          @click="addSelectedChannel(item)"
        >
        </v-checkbox>
      </template>
      <template #[`item.limited_channel`]="{ item }">
        <v-checkbox
          v-model="item.limited_channel"
          class="align-center justify-center"
          @click="addSelectedChannel(item)"
        >
        </v-checkbox>
      </template>
      <template #[`item.enabled_channel`]="{ item }">
        <div v-if="!item.limited_channel || item.enabled_channel.length === 0" class="my-2">
          {{ item.limited_channel ? 'No Selected Channel' : 'All Channel' }}
        </div>
        <div v-else class="my-2">
          <div v-for="(data, index) of item.enabled_channel" :key="index">
            {{ getChannelsName(data) }}
          </div>
        </div>
      </template>
      <template #[`item.enabled_menu`]="{ item }">
        <div v-if="!item.limited_menu || item.enabled_menu.length === 0" class="my-2">
          {{ item.limited_menu ? 'No Selected Menu' : 'All Menu' }}
        </div>
        <div v-else class="my-2">
          <div v-for="(data, index) of item.enabled_menu" :key="index">
            {{ `${$t('item' + data)}` }}
          </div>
        </div>
      </template>
      <template #[`item.limited_menu`]="{ item }">
        <v-checkbox v-model="item.limited_menu" @click="addSelectedChannel(item)"> </v-checkbox>
      </template> -->
      <template #[`item.delete`]="{ item }">
        <v-tooltip v-if="user.role === 'Admin' || user.email === item.email" bottom>
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" dense icon v-on="on" @click="addSelectedChannel(item)">
              <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('user.edit') }}</span>
        </v-tooltip>

        <v-tooltip v-if="user.role === 'Admin' || user.email === item.email" bottom>
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" dense icon v-on="on" @click=";(deleteUserdata = item), (confirmDeleteMessage = true)">
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('user.delete') }}</span>
        </v-tooltip>

        <v-tooltip v-if="user.role === 'Admin' || user.email === item.email" bottom>
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" dense icon v-on="on" @click="changePasswordDialog(item.id)">
              <v-icon>{{ icons.mdiFormTextboxPassword }}</v-icon>
            </v-btn>
          </template>
          <span>Change Password</span>
        </v-tooltip>

        <!-- <v-icon
            dark
            left
          >
            {{ icons.mdiPencilOutline }}
          </v-icon> -->
        <!-- <v-icon
          dark
          left
          @click=";(deleteUserdata = item), (confirmDeleteMessage = true)"
        >
          {{ item.role === 'Admin' ? '' : icons.mdiDeleteOutline }}
        </v-icon> -->
      </template>
      <!-- Channel -->
      <template slot="no-data">
        {{ $t('noDataLabel') }}
      </template>
    </v-data-table>
    <!-- Role Settiings -->
    <v-dialog v-model="dialogRoleSettings" width="800px" persistent>
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">Role Settings</h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="error"
            class="mr-3 hide-buton"
            @click="dialogSelectChannel = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveChannel"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <v-divider />

        <!-- <v-card-text>
          <v-data-table
            :headers="roleHeaders"
            item-key="role"
            :items="roleSettings"
            :items-per-page="10"
            :sort-desc="[true]"
            :loading="loading"
            multi-sort
            class="text-no-wrap text-center"
            :footer-props="{
              'items-per-page-text': $t('rowsPerPageLabel'),
            }"
          >
          </v-data-table>

          <v-switch
            v-model="currentItem.auto_assign"
            label="Auto Assign"
          >
          </v-switch>
        </v-card-text> -->
        <v-divider class="" />
        <v-card-actions class="justify-end text-end">
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="closeDialog">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="saveChannel">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog select channel -->
    <v-dialog v-model="dialogSelectChannel" width="600px" persistent>
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('edit') + ' ' + 'User' }}
          </h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="error"
            class="mr-3 hide-buton"
            @click="dialogSelectChannel = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveChannel"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <v-divider />

        <v-card-text>
          <!-- <v-switch
            v-model="currentItem.auto_assign"
            label="Auto Assign"
          >
          </v-switch> -->
          <v-row>
            <v-col cols="6" sm="6">
              <v-text-field v-model="currentItem.name" label="Name" outlined dense hide-details></v-text-field>
            </v-col>

            <v-col cols="6" sm="6">
              <v-select
                :items="roleSettings.filter(el => el.role !== 'Admin')"
                item-text="role"
                label="Role"
                item-value="id"
                :disabled="currentItem.role === 'uwhu033chq9p4tm' || user.role !== 'Admin'"
                dense
                outlined
                :menu-props="{ offsetY: true }"
                v-model="currentItem.role"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="currentItem.phone_number"
                label="Phone *"
                outlined
                dense
                type="number"
                :rules="[v => !v || v.length >= 10]"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-switch
            v-model="currentItem.view_assign_only"
            :disabled="currentItem.role === 'Admin'"
            :label="$t('history.viewAssignOnly')"
          >
          </v-switch> -->
          <!-- <v-switch
            v-if="!currentItem.view_assign_only"
            v-model="currentItem.limited_channel"
            :disabled="currentItem.role === 'Admin'"
            :label="$t('history.viewAssignOnly')"
          >
          </v-switch> -->
          <!-- <v-switch
            v-model="currentItem.limited_channel"
            :disabled="currentItem.role === 'Admin' || currentItem.view_assign_only"
            :label="$t('history.limitedChannel')"
          >
          </v-switch>
          <div v-if="currentItem.limited_channel">
            <div v-for="(data, index) of listInstances" :key="index">
              <v-checkbox
                v-model="dataSubId[index]"
                :label="
                  data.label.startsWith('-') || data.label.includes('Default') || data.label.includes('62')
                    ? data.label_server
                    : data.label
                "
              >
              </v-checkbox>
            </div>
          </div>
          <v-switch
            v-if="!currentItem.view_assign_only"
            v-model="currentItem.limited_menu"
            :disabled="currentItem.role === 'Admin'"
            label="Limited Menu"
          >
          </v-switch>
          <div v-if="currentItem.limited_menu">
            <div v-for="(data, index) of listMenu" :key="index">
              <v-checkbox
                :class="
                  (index > 4 && index < 9) || (index > 11 && index < 17) || (index > 17 && index < 21)
                    ? `ms-9 my-n1`
                    : ``
                "
                v-model="dataMenu[index]"
                :disabled="!currentItem.limited_menu || currentItem.view_assign_only"
                :label="$t('item' + data)"
              >
              </v-checkbox>
            </div>
          </div> -->
        </v-card-text>
        <v-divider class="" />

        <v-snackbar v-model="dialogErrorMessage" color="error" :top="true" style="z-index: 5 !important">
          {{ errorMessage }}
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="dialogErrorMessage = false">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-snackbar>

        <v-card-actions class="justify-end text-end">
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3 mt-3" @click="closeDialog">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="mt-3" @click="saveChannel">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog add operator -->
    <v-dialog v-model="dialogOperator" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('add') + ' ' + 'User' }}
          </h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="error"
            class="mr-3 hide-buton"
            @click=";(dialogOperator = false), (model.operatorEmail = '')"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveUser"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <v-divider />
        <v-snackbar v-model="dialogErrorMessage" color="error" :top="true" style="z-index: 5 !important">
          {{ errorMessage }}
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="dialogSucces = false">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-snackbar>

        <v-card-text>
          <v-form @submit.prevent="saveUser()">
            <v-row>
              <v-col cols="6" class="mt-3">
                <v-text-field
                  v-model="model.operatorEmail"
                  label="User Email *"
                  outlined
                  autocomplete="off"
                  dense
                  placeholder="User Email"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  class="mt-3"
                  dense
                  outlined
                  :items="roleSettings.filter(el => el.role !== 'Admin')"
                  v-model="chosenRole"
                  :menu-props="{ top: true, offsetY: true }"
                  label="Role"
                  item-value="id"
                  item-text="role"
                  @change="e => (labelOwner = e)"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="">
                <v-text-field
                  v-model="password.password"
                  label="New Password"
                  outlined
                  type="password"
                  dense
                  autocomplete="null"
                  placeholder="New Password"
                  hide-details
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :type="isPasswordVisible ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-3">
                <v-text-field
                  v-model="password.passwordConfirm"
                  label="Confirm Password"
                  outlined
                  type="password"
                  dense
                  autocomplete="null"
                  placeholder="Confirm Password"
                  hide-details
                  :type="isPasswordConfirmVisible ? 'text' : 'password'"
                  :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" class="mt-3">
                <v-text-field
                  v-model="model.operatorNickname"
                  label="Name *"
                  outlined
                  autocomplete="null"
                  dense
                  placeholder="User Name"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="mt-3">
                <v-text-field
                  v-model="model.operatorPhone"
                  label="Phone *"
                  outlined
                  dense
                  autocomplete="null"
                  type="number"
                  placeholder="Phone Number"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="" />
        <v-card-actions class="justify-end text-end">
          <v-btn
            color="#ffeeee"
            class="mr-3"
            @click="
              ;(dialogOperator = false),
                (model.operatorEmail = ''),
                (chosenRole = ''),
                (model.operatorNickname = ''),
                (model.operatorPhone = ''),
                (password.password = ''),
                (password.passwordConfirm = '')
            "
          >
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="saveUser">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog eror message -->

    <!-- dialog alert -->
    <v-dialog v-model="dialogAlert" width="500">
      <v-card>
        <v-card-title color="error">
          {{ $t('warning') }}
        </v-card-title>
        <v-divider />

        <v-card-text>
          {{ alertText ? alertText : '' }}
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialogAlert = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- confirm dialog message delete -->
    <v-dialog v-model="confirmDeleteMessage" persistent width="600px">
      <v-card>
        <v-snackbar v-model="dialogErrorMessage" color="error" :top="true" style="z-index: 5 !important">
          {{ errorMessage }}
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="dialogErrorMessage = false">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ 'Warning!!!' }}
          </h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="error"
            class="mr-3 hide-buton"
            @click="confirmDeleteMessage = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="deleteUser(deleteUserdata)"
          >
            OK
          </v-btn> -->
        </v-card-actions>
        <v-divider />

        <v-card-text>
          {{ $t('OperatorsTranslate.confirmWant') }} <strong> {{ deleteUserdata.email }} </strong>
          {{ $t('OperatorsTranslate.meaningAction') }}
        </v-card-text>
        <v-divider class="" />
        <v-card-actions class="justify-end text-end">
          <v-btn color="#ffeeee" class="mr-3" @click="confirmDeleteMessage = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="deleteUser(deleteUserdata)"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="dialogSucces" color="primary" :top="true" style="z-index: 5 !important">
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="dialogSucces = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogPassword" width="600px" persistent>
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('change') + ' ' + 'Password' }}
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />
        <v-snackbar v-model="dialogErrorMessage" color="error" :top="true" style="z-index: 5 !important">
          {{ errorMessage }}
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="dialogErrorMessage = false">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-snackbar>

        <v-card-text>
          <v-form @submit.prevent="saveUser()">
            <v-row>
              <v-col cols="12" class="mt-3">
                <v-text-field
                  v-model="password.password"
                  label="New Password"
                  outlined
                  type="password"
                  dense
                  placeholder="New Password"
                  hide-details
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :type="isPasswordVisible ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-3">
                <v-text-field
                  v-model="password.passwordConfirm"
                  label="Confirm Password"
                  outlined
                  dense
                  placeholder="Confirm Password"
                  hide-details
                  :type="isPasswordConfirmVisible ? 'text' : 'password'"
                  :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="" />
        <v-card-actions class="justify-end text-end">
          <v-btn
            color="#ffeeee"
            class="mr-3"
            @click=";(dialogPassword = false), (password.password = ''), (password.passwordConfirm = '')"
          >
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="savePassword">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable */
// import PouchDB from 'pouchdb-browser'
import { default as clientPocketBase } from '@/helpers/pocketbase'
import {
  mdiCalendarMonth,
  mdiChartBar,
  mdiCloseCircle,
  mdiDeleteOutline,
  mdiExport,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFormTextboxPassword,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlusCircle,
} from '@mdi/js'
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import { TimeFormater } from './../../../helpers'
// PouchDB.adapter('worker', require('worker-pouch'))
export default {
  name: 'OperatorList',
  components: {
    VueApexCharts,
  },
  // mixins: [pouchDbMixin],
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiPencilOutline,
        mdiCalendarMonth,
        mdiFormTextboxPassword,
        mdiEyeOffOutline,
      },
    }
  },
  data() {
    return {
      //chart
      dialogRoleSettings: false,
      roleSettings: [],
      chartCreatedAt: {},
      userDataCreated: [],
      menu: false,
      rangeDates: [],
      dates: [
        new Date(new Date().setDate(new Date().getDate() - 5)).toISOString().substring(0, 10),
        new Date().toISOString().substring(0, 10),
      ],
      // DIALOG Error Message
      dialogErrorMessage: false,
      errorMessage: '',

      // Confirm delete User
      confirmDeleteMessage: false,
      deleteUserdata: {},

      //loading
      loading: false,

      // SETTING
      columnsSetting: null,
      settingFilterSetting: null,
      tableColumns: [],
      itemsFilter: [],
      // DIALOG Add Operator
      dialogOperator: false,
      model: {
        operatorEmail: '',
        operatorNickname: '',
        operatorPhone: '',
      },
      alertText: '',

      dialogAlert: false,
      // Dialog Add Channel
      dialogSelectChannel: false,
      dataSubId: [],
      dataMenu: [],
      dataMenuSub: [],
      currentItem: {
        role: '',
        phone_number: '',
        name: '',
        auto_assign: false,
        view_assign_only: false,
        limited_channel: false,
        enabled_channel: [],
        limited_menu: false,
        enabled_menu: [],
      },
      chosenRole: '',
      dataListUsers: [],
      selectedUserId: '',
      dialogPassword: false,
      password: {
        oldPassword: '',
        password: '',
        passwordConfirm: '',
      },
      dialogSucces: false,
      isPasswordVisible: false,
      isPasswordConfirmVisible: false,
      listInstances: [],
    }
  },
  computed: {
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    // listInstances() {
    //   const inst = this.$store.getters['auth/getListInstance']
    //   if (inst) {
    //     return inst.value
    //   } else {
    //     return []
    //   }
    // },
    listWorkspaces() {
      return this.$store.getters['auth/getListWorkspaces']
    },
    roleHeaders() {
      //     role: "Admin",
      //     view_assign_only: false,
      //     limited_channel: false,
      //     enabled_channel: [],
      //     limited_menu: false,
      //     enabled_menu: [],
      //     bold_unreplied: false,
      //     auto_assign: false,
      //     notif_all_or_assigned:{data: 2, name: "Only get notification from Active Channel"},
      //     filter: "all",
      //     sort: "newest"
      return [
        { text: this.$t('action'), value: 'delete', sortable: false },
        { text: this.$t('history.role'), value: 'role', sortable: true },
        // { text: this.$t('history.customerServed'), value: 'customer_served', sortable: true, align: 'center' },
        // { text: this.$t('history.avgReply'), value: 'avg_reply', sortable: true },
        { text: this.$t('history.viewAssignOnly'), value: 'view_assign_only', sortable: false, align: 'center' },
        // { text: this.$t('history.autoAssign'), value: 'auto_assign', sortable: false },
        { text: this.$t('history.limitedChannel'), value: 'limited_channel', sortable: false, align: 'center' },
        { text: this.$t('history.channelList'), value: 'enabled_channel', sortable: false, align: 'center' },
        { text: this.$t('history.limitedMenu'), value: 'limited_menu', sortable: false, align: 'center' },
        { text: this.$t('history.enabledMenu'), value: 'enabled_menu', sortable: false, align: 'center' },
      ]
    },
    headers() {
      return [
        { text: this.$t('action'), value: 'delete', sortable: false },
        { text: 'Email', value: 'email', sortable: true },
        { text: this.$t('history.role'), value: 'role', sortable: true },
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Phone Number', value: 'phone_number', sortable: true },
        { text: 'Assigned Chat', value: 'assign_chat', sortable: true },

        { text: 'Status', value: 'status', sortable: true },

        // { text: this.$t('history.customerServed'), value: 'customer_served', sortable: true, align: 'center' },
        // { text: this.$t('history.avgReply'), value: 'avg_reply', sortable: true },
        // { text: this.$t('history.viewAssignOnly'), value: 'view_assign_only', sortable: false, align: 'center' },
        // { text: this.$t('history.autoAssign'), value: 'auto_assign', sortable: false },
        // { text: this.$t('history.limitedChannel'), value: 'limited_channel', sortable: false, align: 'center' },
        // { text: this.$t('history.channelList'), value: 'enabled_channel', sortable: false, align: 'center' },
        // { text: this.$t('history.limitedMenu'), value: 'limited_menu', sortable: false, align: 'center' },
        // { text: this.$t('history.enabledMenu'), value: 'enabled_menu', sortable: false, align: 'center' },
      ]
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    listMenu() {
      return this.user.package === 'basic'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Broadcasts']
        : this.user.package === 'pro'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts']
        : this.user.package === 'business'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users']
        : [
            'Chat',
            'Send Broadcast',
            'Contact List',
            'Channels',
            'Inbox',
            'Broadcast Timeline',
            'Template',
            'Label',
            'Chat Data',
            'Broadcasts',
            'Users',
            'Business+',
            'My Apps',
            'E-Shop',
            'E-Form',
            'Campaign Tracker',
            'Login by Whatsapp',
            'Statistics',
            'Broadcast Statistic',
            'User Perfomance',
            'Chat Statistic',
          ]
    },
    viewAssign() {
      return this.currentItem.view_assign_only
    },
    limitedChannel() {
      return this.currentItem.limited_channel
    },
    limitedMenu() {
      return this.currentItem.limited_menu
    },
  },
  async mounted() {
    this.loading = true
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.listInstances = instances?.value || []
    // await this.getStatisticChart(this.dates)
    // await clientPocketBase.admins.authWithPassword(process.env.VUE_APP_PB_USER, process.env.VUE_APP_PB_PASS)
    // try {
    //   const { data: users } = await axios.post(`${process.env.VUE_APP_WA_SERVICES_URL}teams`, {
    //     sub_id: this.user.sub_id,
    //   })
    //   this.dataListUsers = users?.data || []
    // } catch (error) {
    //   console.log(error, 'error fetch')
    // }
    this.fetchUsers()
    try {
      this.roleSettings = await clientPocketBase.collection('crm_role').getFullList(100, {
        $autoCancel: false,
      })
    } catch (err) {
      console.log(err, 'err fetch role settings')
    }
  },
  watch: {
    viewAssign() {
      if (this.currentItem.view_assign_only) {
        this.currentItem.limited_channel = false
        this.currentItem.limited_menu = false
        this.dataMenu = []
        this.dataSubId = []
      }
    },
    limitedChannel() {
      if (!this.currentItem.limited_channel) {
        this.dataSubId = []
      }
    },
    limitedMenu() {
      if (!this.currentItem.limited_menu) {
        this.dataMenu = []
      } else {
        if (this.currentItem.enabled_menu.length === 0) {
          this.dataMenu =
            this.user.package === 'basic'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Broadcasts']
              : this.user.package === 'pro'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts']
              : this.user.package === 'business'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users']
              : [
                  'Chat',
                  'Send Broadcast',
                  'Contact List',
                  'Channels',
                  'Inbox',
                  'Broadcast Timeline',
                  'Template',
                  'Label',
                  'Chat Data',
                  'Broadcasts',
                  'Users',
                  'Business+',
                  'My Apps',
                  'E-Shop',
                  'E-Form',
                  'Campaign Tracker',
                  'Login by Whatsapp',
                  'Statistics',
                  'Broadcast Statistic',
                  'User Perfomance',
                  'Chat Statistic',
                ]
        }
      }
    },
  },
  methods: {
    async changePasswordDialog(id) {
      try {
        this.dialogPassword = true
        this.selectedUserId = id
      } catch (error) {
        console.log(error)
      }
    },
    async savePassword() {
      // console.log('sayang masuk save')
      try {
        if (!this.password.password) {
          throw 'newPassword empty'
        }
        if (!this.password.passwordConfirm) {
          throw 'confirmPassword empty'
        }
        if (this.password.passwordConfirm !== this.password.password) {
          throw 'not the same'
        }
        if (this.password.password.length < 8) {
          throw 'not long enough'
        }
        const changePassword = await clientPocketBase.collection('users').update(this.selectedUserId, this.password)
        console.log(changePassword, 'succes change password')
        this.dialogPassword = false
        this.password = {
          oldPassword: '',
          password: '',
          passwordConfirm: '',
        }
        this.dialogSucces = true
        this.errorMessage = 'Succes change your password'
      } catch (error) {
        console.log(error, 'error change password')
        if (error) {
          this.errorMessage = 'Failed to change password'
          this.dialogErrorMessage = true
        }
        if (error === 'oldPassword empty') {
          this.errorMessage = 'Please Input your old password'
          this.dialogErrorMessage = true
        } else if (error === 'newPassword empty') {
          this.errorMessage = 'Please Input your new password'
          this.dialogErrorMessage = true
        } else if (error === 'confirmPassword empty') {
          this.errorMessage = 'Please Input your confirm password'
          this.dialogErrorMessage = true
        } else if (error === 'not the same') {
          this.errorMessage = 'Your new password is not the same as your confirm password'
          this.dialogErrorMessage = true
        } else if (error === 'not long enough') {
          this.errorMessage = 'Your password is too short minimum 8 characters'
          this.dialogErrorMessage = true
        }
        this.password = {
          oldPassword: '',
          password: '',
          passwordConfirm: '',
        }
      }
    },
    async closeDialog() {
      this.dialogSelectChannel = false
      this.loading = true
      await this.$store
        .dispatch('auth/fetchListUser', {
          sub_id: this.user.sub_id,
        })
        .then(data => {
          this.loading = false
        })
    },
    async fetchUsers() {
      let usersTemp = []
      try {
        this.loading = true
        const { data: users } = await axios.post(`${process.env.VUE_APP_WA_SERVICES_URL}teams`, {
          sub_id: this.user.sub_id,
        })
        for (let i = 0; i < users.data.length; i++) {
          const user = users.data[i]
          try {
            console.time('test')
            if(user.assign_chat_total === null){
              const assignData = await clientPocketBase.collection('crm_contact').getFullList(400, {
                filter: `assign_to = '${user.id}'`,
                $autoCancel: false,
              })
              console.timeEnd('test')
              user.assign_chat =
                assignData &&
                assignData.map(el => {
                  return el.phone_number
                })
              user.assign_chat_total = assignData ? assignData.length : 0
            }
            usersTemp.push(user)
          } catch (error) {
            console.log(error)
          }
        }
        this.dataListUsers = usersTemp
        this.loading = false
      } catch (err) {}
    },
    async changeStatus(status, id) {
      try {
        const createdUser = await clientPocketBase.collection('users').update(id, {
          isActive: status,
        })
      } catch (error) {
        console.log(error)
      }
    },
    async saveChannel(status) {
      try {
        if (!this.currentItem.role) {
          throw 'role empty'
        }
        if (!this.currentItem.name) {
          throw 'nickname empty'
        }
        if (!this.currentItem.phone_number) {
          throw 'phone empty'
        }
        if (this.currentItem.phone_number.toString().length < 10) {
          throw 'phone lower than 10'
        }

        const createdUser = await clientPocketBase.collection('users').update(
          this.selectedUserId,
          {
            crmRole: this.currentItem.role,
            name: this.currentItem.name,
            phone_number: this.currentItem.phone_number,
          },
          { $autoCancel: false },
        )
        this.dialogSelectChannel = false
        this.loading = true
        this.selectedUserId = ''
        await this.fetchUsers()
        this.loading = false
      } catch (error) {
        console.log(error, 'cok<')
        if (error === 'role empty') {
          this.errorMessage = 'Please Input Role'
          this.dialogErrorMessage = true
        } else if (error === 'email empty') {
          this.errorMessage = 'Please Input Email'
          this.dialogErrorMessage = true
        } else if (error === 'email wrong format') {
          this.errorMessage = 'Please Input in email Format'
          this.model.operatorEmail = ''
          this.dialogErrorMessage = true
        } else if (error === 'nickname empty') {
          this.errorMessage = 'Please Input Name'
          this.dialogErrorMessage = true
        } else if (error === 'phone empty') {
          this.errorMessage = 'Please Input Phone Number'
          this.dialogErrorMessage = true
        } else if (error === 'phone lower than 10') {
          this.errorMessage = 'Phone number must be greater than 10 digit'
          this.dialogErrorMessage = true
        }
      }
    },
    getChannelsName(id) {
      const obj = this.listInstances.find(x => x._id === id)
      if (obj) {
        return (
          (obj.label.startsWith('-') || obj.label.includes('Default') || obj.label.includes('62')
            ? obj.label_server
            : obj.label) +
          ' (' +
          obj.phone_number +
          ')'
        )
      }
      return ''
    },
    async addSelectedChannel(item) {
      this.dialogSelectChannel = true
      this.selectedUserId = item.id
      item.role = item.crmRole
      this.currentItem = {
        ...item,
      }
      this.listInstances.forEach((instance, index) => {
        this.dataSubId[index] = item.enabled_channel && item.enabled_channel.findIndex(x => x === instance._id) > -1
      })
      //  //console.log(this.listMenu)
      this.listMenu.forEach((menu, index) => {
        this.dataMenu[index] = item.enabled_menu && item.enabled_menu.findIndex(x => x === menu) > -1
      })
    },
    async addOperator() {
      // let limitOps = await this.$store.dispatch('subscriptions/getSubscriptionInfo', this.user)
      //console.log(limitOps, this.dataListUsers, this.user,"<<<<aaaaaaaaaaaaaaaaaa")
      this.dialogOperator = true
      // if (this.dataListUsers.length < limitOps.data.data.dataValues.max_operator) {
      //   this.dialogOperator = true
      // } else {
      //   this.showAlert(this.$t('warningmaxoperator'))
      // }
    },
    showAlert(warningText) {
      this.alertText = warningText
      this.dialogAlert = true
    },
    msToTime(duration) {
      return TimeFormater.msToTime(duration)
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    async saveUser() {
      try {
        console.log('masuk paaji<')
        if (!this.model.operatorEmail) {
          throw 'email empty'
        }
        if (!this.validateEmail(this.model.operatorEmail)) {
          throw 'email wrong format'
        }
        if (!this.chosenRole) {
          throw 'role empty'
        }
        if (!this.model.operatorNickname) {
          throw 'name empty'
        }
        if (!this.model.operatorPhone) {
          throw 'phone empty'
        }
        if (!this.password.password) {
          this.password.password = this.model.operatorEmail
          this.password.passwordConfirm = this.model.operatorEmail
        }
        if (this.password.password !== this.password.passwordConfirm) {
          throw 'not same'
        }
        if (this.model.operatorPhone.toString().length < 10) {
          throw 'phone lower than 10'
        }

        // const currentWorkspace = this.listWorkspaces.find(
        //   workspace => workspace.role === 'Admin' && workspace.sub_id === this.user.sub_id,
        // )
        //console.log(currentWorkspace, this.listWorkspaces, `INI DIA`)
        try {
          const { data: users } = await axios.post(`${process.env.VUE_APP_WA_SERVICES_URL}teams/add`, {
            sub_id: this.user.sub_id,
            email: this.model.operatorEmail,
            crmRole: this.chosenRole,
            name: this.model.operatorNickname,
            phone_number: this.model.operatorPhone,
            password: this.password.password,
            passwordConfirm: this.password.passwordConfirm,
          })
          this.dialogOperator = false
          this.model.operatorEmail = ''
          this.chosenRole = ''
          this.model.operatorNickname = ''
          this.model.operatorPhone = ''
          await this.fetchUsers()
        } catch (error) {
          // console.log('sayang', error)
          this.errorMessage = 'Failed to create, email has been regitered'
          this.dialogErrorMessage = true
        }
        this.password.password = ''
        this.password.passwordConfirm = ''
      } catch (error) {
        console.log(error, 'helo<')
        if (error === 'role empty') {
          this.errorMessage = 'Please Input Role'
          this.dialogErrorMessage = true
        } else if (error === 'email empty') {
          this.errorMessage = 'Please Input Email'
          this.dialogErrorMessage = true
        } else if (error === 'email wrong format') {
          this.errorMessage = 'Please Input in email Format'
          this.model.operatorEmail = ''
          this.dialogErrorMessage = true
        } else if (error === 'name empty') {
          this.errorMessage = 'Please Input Name'
          this.dialogErrorMessage = true
        } else if (error === 'phone empty') {
          this.errorMessage = 'Please Input Phone Number'
          this.dialogErrorMessage = true
        } else if (error === 'phone lower than 10') {
          this.errorMessage = 'Phone number must be greater than 10 digit'
          this.dialogErrorMessage = true
        } else if (error === 'not same') {
          this.errorMessage = 'Wrong password confirmation'
          this.dialogErrorMessage = true
        }
      }
    },
    async deleteUser(data) {
      try {
        const foundUser = this.dataListUsers.find(el => el.id == data.id)
        // console.log('sayang', foundUser);
        if (foundUser['assign_chat_total'] > 0) {
          throw 'haveContacts'
        }
        if (data.isActive) {
          throw 'stillActive'
        }
        try {
          this.loading = true
          // console.log('sayang masuk delete')
          await clientPocketBase.collection('users').delete(data.id, { $autoCancel: false })
          this.loading = false
          this.deleteUserdata = {}
          this.confirmDeleteMessage = false
          await this.fetchUsers()
        } catch (err) {
          this.dialogErrorMessage = true
          console.log(err, 'err delete')
        }
        // if (res.status) {
        //   await this.$store.dispatch('auth/fetchListUser', {
        //     sub_id: this.user.sub_id,
        //   })
        // } else {
        //   this.errorMessage = res.errorMessage
        //   this.dialogErrorMessage = true
        // }
      } catch (error) {
        if (error === 'stillActive') {
          this.dialogErrorMessage = true
          this.errorMessage = 'Cannot delete active user'
        } else if (error === 'haveContacts') {
          this.dialogErrorMessage = true
          this.errorMessage = 'Cannot delete user that have assigned contacts'
        }
      }
      // var res = await this.$store.dispatch('auth/deleteUser', {
      //   email: data.email,
      //   sub_id: data.sub_id,
      //   role: data.role,
      // })
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.table-broadcast ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
.chip-margin-left {
  margin-left: 1px;
}
.search-in-detail-status {
  margin-right: 5px;
  margin-bottom: 5px;
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
.contact-tooltip {
  color: #03a9f4; /* Choose the color you prefer */
  cursor: pointer; /* Indicates that it is clickable */
}
</style>
