var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"example"},[_c('v-card-text',[_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('broadcasts.date'),"prepend-icon":_vm.icons.mdiCalendarClock,"readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#ffeeee"},on:{"click":function($event){_vm.menu = false}}},[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('cancel')))])]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.updateTableHistory}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"block":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlusCircle)+" ")]),_c('vue-excel-xlsx',{staticClass:"primary-text",attrs:{"data":_vm.broadcastListAll,"columns":_vm.historyListTable,"file-name":'HistoryTable',"file-type":'xlsx',"sheet-name":'HistoryTable'}},[_vm._v(" "+_vm._s(_vm.$t('exportToCsv'))+" ")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"text-no-wrap text-center",attrs:{"item-key":"table_id","headers":_vm.headers,"items":_vm.historyListTableFilter,"items-per-page":10,"sort-desc":"","loading":_vm.loading,"multi-sort":"","sort-by":"timestamp","footer-props":{
      'items-per-page-text': _vm.$t('rowsPerPageLabel'),
    }},scopedSlots:_vm._u([{key:"item.rating",fn:function(ref){
    var item = ref.item;
return [_c('v-rating',{attrs:{"background-color":"warning","color":"warning"},on:{"input":function($event){return _vm.setRatingHistoryOperatorFirestore(item)}},model:{value:(item.rating),callback:function ($$v) {_vm.$set(item, "rating", $$v)},expression:"item.rating"}})]}},{key:"item.chat",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openShow5History(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])]}},{key:"item.reply_time",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.msToTime(item.reply_time))+" ")]}},{key:"item.duration",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.msToTime(item.duration))+" ")]}},{key:"item.timestamp",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormater(new Date(item.timestamp * 1000)))+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"width":"700px"},model:{value:(_vm.show5History),callback:function ($$v) {_vm.show5History=$$v},expression:"show5History"}},[_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('history.headerMarkupTable'))+" ")])],1)],1)],1),_c('v-card-text',[(_vm.latestMessage.length !== 0)?_c('div',[_c('chat-window',{attrs:{"height":"300px","current-user-id":_vm.currentUserId,"rooms":_vm.rooms,"rooms-origin":_vm.rooms,"messages":_vm.messages,"rooms-loaded":true,"messages-loaded":true,"single-room":true,"loading-rooms":false,"show-add-room":false,"show-new-messages-divider":false,"show-reaction-emojis":false,"show-footer":false,"show-search":false,"menu-actions":_vm.menuActions,"message-actions":_vm.messageActions,"hide-messages-started":true,"replies-history":true},on:{"fetch-messages":_vm.fetchMessagesHistory,"open-file":_vm.openFile}})],1):_vm._e(),(_vm.latestMessage.length === 0)?_c('v-card-title',{staticClass:"justify-center"},[_c('h6',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('history.chatHistoryData'))+" ")])]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.closeShow5History}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }